body 
{
    background-color:white;
}
.container
{
    background-color:white;
}
.page-header {
    margin-bottom: 0px;
    height:100px;
    color: white; /* Le texte sera affiché en blanc */
   
    background-color: #F7941D;
   }
.page-header>h2{
    padding-top: 25px;
    text-decoration: underline;
}
.txt-j
{
    text-align: justify;
}
.button-back
{
    display: flex;
    justify-content: center;
}

/* section base inclue dans la plupart des pages */
/* navbar fixe */
.navbar.fixed{
    position:fixed;
    width: 100%;
    top:0;
    z-index:1000;
  }
.logo-at
{
    width: 175px;
    height: auto;
}
  /* section footer inclue dans la plupart des pages */
.footer
{   
    padding-top:25px;
    background-color: black;
}
.info
{
    text-align: left;
}
.credit, td{
    color: white;
}
.credit{
    text-align: center;
}
.info>h5
{
    color: white;
    text-decoration: underline;
}
.lien
{
    text-decoration: none;
    color:white;
}
/* La page home */
.a-icone
{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    text-align: center;
}
.icone
{
    width: 150px;
    height: auto;
}
/* bannière */
.banniere
{
    background: #F7941D;
    color: #fff;
}

.defileBanniere {
	display: block;
	margin: 3em auto;
	overflow: hidden;
	position: relative;
	table-layout: fixed;
	width: 700px;
}
.defile {
	display:block;
	animation:linear marqueelike 25s infinite ;
	margin-left:-100%;
	padding:0 5px;
	text-align:left;
	height:40px;
}
.defile:after {
	content:attr(data-text);
	position:absolute;
	white-space:nowrap;
	padding-left:10px;
}

@keyframes marqueelike 
{
    0%, 100% {margin-left:0;}
    99.99% {margin-left:-100%;}
}

/* image parallax */
.paral 
{
    min-height: 500px;
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 50%;
}
.paralsec 
{
	background-image: url(/build/parallax.jpg);
}
.space30
{
    height: 30px;
}
.space90
{
    height: 90px;
}
.savoir
{
    background-color:white;
}
.button-info
{
    display: block;
    margin : auto;
    background-color: #F7941D;

}
.centre
{
    text-align: center;
    color: #F7941D;
}
.centre-txt
{
    text-align: center;
}

.button-info>a 
{ 
    text-decoration: none; 
    color: white; 
}

/* gestion du carousel de petite image */ 
.img-carousel
{
    float:left;
}
/* gestion du bandeau orange pour le contact */ 
.bandeau
{
    padding-top: 60px;
    padding-bottom: 50px;
    background-color: #F7941D;
}
.bandeau-titre
{
    color:white;
}
.bandeau-bouton
{
    background-color: white;
    border-radius: 100px;
    
}
.bandeau-bouton>a
{
    text-decoration: none; 
    color: black;
}
.bandeau-bouton:hover
{
    background-color: black;
}
.bandeau-bouton>a:hover
{
    color:white;
}

/* La page equipe */
.presentation
{
    background-color: #F7941D;
    width: 100%;
    height: 350px;
    align-items: center;
}

.contenu
{
    text-align: center;
    color:white;
}
.contain
{
    max-width: 1000px;
    margin: 10px auto;
    display:flex;
    justify-content: space-around
}
.card
{
    position: relative;
    margin: 20px 0;
    width: 200px;
    height: 250px;
    background: #fff;
    transform-style: preserve-3d;
    transform:perspective(2000px);
    transition: 1s;
    box-shadow: inset 300px 0 50px rgba(0,0,0,.5);
}
.card:hover
{
    z-index: 1000;
    transform: perspective(2000px) rotate(-10deg);
    box-shadow: inset 20px 0 50px rgba(0,0,0,.5);
}
.card .imagebox
{
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    box-sizing: border-box;
    transform-origin: left;
    z-index: 1;
    transition: 1s
}
.card:hover .imagebox
{
    transform: rotateY(-135deg);
}
.card .imagebox .img
{
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card .details
{
    text-align: center;
    width: 200px;
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 20px;

}

/* La page compétences */
.txt-j
{
    text-align: justify;
}

/* La page savoir-faire */
.bouton-prod
{
    
    background-color: #F7941D;
}
.bouton-prod>a
{   
    text-decoration: none;
    color:black;
}
.bouton-prod:hover
{
    
    background-color: black;
}
.bouton-prod>a:hover
{   
    text-decoration: none;
    color:white;
}
.txt-c
{
    text-align: center;
}
 /* Les images sont sur la même ligne */
.image-alg
{
    display: flex;
    justify-content: space-between;
}
/* effet de zoom dans une fenetre modale image savoir faire */
.modal-dialog 
{
    max-width: 800px;
    margin: 30px auto;
}
.modal-body 
{
position:relative;
padding:0px;
min-height:400px;
background:#ccc;
}
.close 
{
position:absolute;
right:-30px;
top:0;
z-index:999;
font-size:2rem;
font-weight: normal;
color:#fff;
opacity:1;
}
#image 
{
min-height:200px;
}
/* La page partenaire */
.logo
{
    width: 100px;
    height: auto;
}
.part
{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
    position: relative;
}
.part-image-l
{
    position: absolute;
  top: 50%; /* poussé de la moitié de hauteur du référent */
  left: 50%;
  transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
}
.part-image-r
{
    position: absolute;
  top: 50%; /* poussé de la moitié de hauteur du référent */
  right: 50%;
  transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
}
/* La page contact */
.contact
{
    text-align: center;
}
.contact>h2
{
    color:#F7941D;
    text-decoration: underline;
}
.contact-obli
{
    text-align: justify;
}
.contact-obli>p
{
    font-size: x-small;
}

/* La page legal */
.legal>tr>td
{
    color:black;
}
/* La page livre d'or */
.cadre
{
    background-color: #F7941D;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.contenu
{
    text-align: center;
    color:white;
}
.hidden 
{
    display: none;
}
.comments
{
    height: 50px;
    border-bottom: 1px solid black; 
}
.comm
{
    text-align: center;
}
.connex
{
    text-decoration: none;
    color:white;
}
.connex:hover
{
    text-decoration: none;
    color:white;
}

/* Partie responsive */
@media only screen and (max-width: 860px) {
    .defileBanniere {
        display: block;
        margin: 3em auto;
        overflow: hidden;
        position: relative;
        table-layout: fixed;
        width: 100%;
    }
    
    .defile {
        display:block;
        animation:linear marqueelike 25s infinite ;
        margin-left:-100%;
        padding:0 5px;
        text-align:left;
        height:25px;
    }
    }

/* Partie responsive pour le mobile */

@media screen and (min-width: 576px) {
    /* navbar */

#menu-prime
{
    text-align: center;
}
.dropdown-item
{
    text-align: center;
}
    /* page home */
    .icone
    {
        width: 75px;
        height: auto;
    }
  }
